<template>
    <div :id="id" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" :class="extraClasses">
            <div class="modal-content">
                <div class="modal-header px-4">
                    <slot name="title"></slot>
                    <button type="button" 
                        class="btn-close" 
                        data-bs-dismiss="modal" 
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { Modal } from '@/assets/bootstrap/bootstrap.bundle.min.js'

export default {
    name: 'BootstrapModal',
    props: {
        id: {
            type: String,
            default: 'bsModal'
        },
        size: {
            type: String,
            default: 'nm'
        },
        vcentered: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => ({ keyboard: false })
        }
    },
    emits: ['hidden-modal', 'hide-modal', 'hidePrevented-modal', 'shown-modal', 'show-modal'],
    setup(props, { expose, emit }) {
        const modal = ref()
        const allowedSizes = ['sm', 'nm', 'lg', 'xl']

        onMounted(() => {
            modal.value = new Modal(document.getElementById(props.id), props.options)

            document.getElementById(props.id).addEventListener('hidden.bs.modal', () => {
                emit('hidden-modal')
            })

            document.getElementById(props.id).addEventListener('hide.bs.modal', () => {
                emit('hide-modal')
            })

            document.getElementById(props.id).addEventListener('shown.bs.modal', () => {
                emit('shown-modal')
            })

            document.getElementById(props.id).addEventListener('show.bs.modal', () => {
                emit('show-modal')
            })

            document.getElementById(props.id).addEventListener('hidePrevented.bs.modal', () => {
                emit('hidePrevented-modal')
            })
        })

        const show = () => {
            if (modal.value) {
                modal.value.show()
            }
        }

        const hide = () => {
            if (modal.value) {
                modal.value.hide()
            }
        }

        const toggle = () => {
            if (modal.value) {
                modal.value.toggle()
            }
        }

        expose({ show, hide, toggle })

        return {
            extraClasses: computed(() => {
                return [
                    props.size === 'nm' || !allowedSizes.includes(props.size) ? undefined : `modal-${props.size}`,
                    props.vcentered ? 'modal-dialog-centered' : undefined
                ].join(' ')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-close {
    margin-top: -15px; 
    font-size: 0.8em;
}
</style>