<template>
    <mnSup mostrar="true" />
    <!--<h1>Stand {{$route.params.r}}</h1> -->

    <main id="stand" v-if="region != null">
        <div class="header">
            <img v-if="widthScreen()" class="imgHeader" :src="region.url" alt="">
            <div v-else>
                <img class="imgMobile" :src="region.url2" alt="">

                <div class="nombre" :class="region.order == 7 ? 'RMstand': ''">
                    <div>
                        <h2>
                            {{ region.nombre === 'Región Metropolitana de Santiago - Norte' ? 'Región Metropolitana de Santiago' : region.nombre }}
                        </h2>
                    </div>
                </div>
            </div>
            <aside>
                <mnRegiones />
            </aside>
        </div>
        <div>
            <ul class="RM-group" v-if="region.order == 7">
                <li>
                    <p @click="loadProyectos(13)" :class="RMselect == 13 ? 'active' : ''">
                        Norte
                    </p>
                </li>
                <li>
                    <p @click="loadProyectos(17)" :class="RMselect == 17 ? 'active' : ''">
                        Sur Oriente
                    </p>
                </li>
                <li>
                    <p @click="loadProyectos(18)" :class="RMselect == 18 ? 'active' : ''">
                        Sur Poniente
                    </p>
                </li>
            </ul>
        </div>

        <div class="grilla">
            <div class="cat1">
                <h2 style="color:var(--color3);">INVESTIGACIÓN</h2>
            </div>
            <div class="cat2">
                <h2 style="color:var(--color2);">INNOVACIÓN</h2>
            </div>
            
            <div class="investigacionB">
                <template v-for="(pr, index) in proyectosB" :key="index"> 
                    <div class="" v-if="pr.categoria == 'Investigación CN' || pr.categoria == 'Investigación CS'">
                        <img class="imgStands" src="/assets/img/stand-investigacion-BASICA.svg" alt="">
                        <div class="ico-stands">
                            <div>
                                <svg id="Capa_1"
                                    modal="resumen" 
                                    class="iconos" version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'TEXT', title: 'RESUMEN', data: pr })"
                                >
                                    <!-- click="modalResumen(pr)"  -->
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <g>
                                                <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                            </g>
                                            <line class="st0" x1="31.8" y1="31" x2="38.6" y2="31" />
                                            <line class="st0" x1="31.8" y1="34.4" x2="38.6" y2="34.4" />
                                            <line class="st0" x1="31.8" y1="38.3" x2="50.1" y2="38.3" />
                                            <line class="st0" x1="31.8" y1="42.1" x2="50.1" y2="42.1" />
                                            <line class="st0" x1="31.8" y1="46" x2="50.1" y2="46" />
                                            <line class="st0" x1="31.8" y1="49.8" x2="50.1" y2="49.8" />
                                        </g>
                                    </g>
                                </svg>
                                <p>RESUMEN</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'VIDEO', title: 'VIDEO', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <path class="st1" d="M55.8,37.1L33.5,24.2c-2-1.2-4.5,0.3-4.5,2.6v25.8c0,2.3,2.5,3.8,4.5,2.6l22.3-12.9C57.9,41.2,57.9,38.3,55.8,37.1z" />
                                    </g>
                                </svg>
                                <p>VIDEO</p>
                            </div>
                            <div>
                                <a :href="`${pr.links.infografia}`" target="_blank" >
                                    <svg id="Capa_1" 
                                        class="iconos" 
                                        version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        x="0px" 
                                        y="0px" 
                                        viewBox="0 0 80 80"
                                        style="enable-background:new 0 0 80 80;" 
                                        xml:space="preserve"
                                    >

                                        <g>
                                            <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                            <g>
                                                <g>
                                                    <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                    <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                                </g>
                                                <circle class="st0" cx="33.9" cy="37.9" r="2.6" />
                                                <polyline class="st0" points="54.8,50.7 47.2,43.4 33.5,56.7 		" />
                                                <polyline class="st0" points="39.4,50.7 34,45.6 27.9,51.5 		" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <p>INFOGRAFIA</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'SHARE', title: 'COMPARTIR', data: $route })"
                                >

                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <circle class="st1" cx="29" cy="40" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="29.1" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="50.9" r="4.4" />
                                            <line class="st0" x1="32.3" y1="43.1" x2="43.2" y2="48.8" />
                                            <line class="st0" x1="32.3" y1="36.9" x2="43.2" y2="31.2" />
                                        </g>
                                    </g>
                                </svg>
                                <p>COMPARTIR</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="innovacionB">
                <template v-for="(pr, index) in proyectosB" :key="index">
                    <div class="" v-if="pr.categoria == 'Innovación'">
                        
                        <img class="imgStands" src="/assets/img/stand-innovacion-BASICA.svg" alt="">
                        <div class="ico-stands">
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'TEXT', title: 'RESUMEN', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <g>
                                                <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                            </g>
                                            <line class="st0" x1="31.8" y1="31" x2="38.6" y2="31" />
                                            <line class="st0" x1="31.8" y1="34.4" x2="38.6" y2="34.4" />
                                            <line class="st0" x1="31.8" y1="38.3" x2="50.1" y2="38.3" />
                                            <line class="st0" x1="31.8" y1="42.1" x2="50.1" y2="42.1" />
                                            <line class="st0" x1="31.8" y1="46" x2="50.1" y2="46" />
                                            <line class="st0" x1="31.8" y1="49.8" x2="50.1" y2="49.8" />
                                        </g>
                                    </g>
                                </svg>
                                <p>RESUMEN</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'VIDEO', title: 'VIDEO', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <path class="st1" d="M55.8,37.1L33.5,24.2c-2-1.2-4.5,0.3-4.5,2.6v25.8c0,2.3,2.5,3.8,4.5,2.6l22.3-12.9C57.9,41.2,57.9,38.3,55.8,37.1z" />
                                    </g>
                                </svg>
                                <p>VIDEO</p>
                            </div>
                            <div>
                                <a :href="`${pr.links.infografia}`" target="_blank" >
                                    <svg id="Capa_1" 
                                        class="iconos" 
                                        version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        x="0px" 
                                        y="0px" 
                                        viewBox="0 0 80 80"
                                        style="enable-background:new 0 0 80 80;" 
                                        xml:space="preserve"
                                    >

                                        <g>
                                            <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                            <g>
                                                <g>
                                                    <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                    <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                                </g>
                                                <circle class="st0" cx="33.9" cy="37.9" r="2.6" />
                                                <polyline class="st0" points="54.8,50.7 47.2,43.4 33.5,56.7 		" />
                                                <polyline class="st0" points="39.4,50.7 34,45.6 27.9,51.5 		" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <p>INFOGRAFIA</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'SHARE', title: 'COMPARTIR', data: $route })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <circle class="st1" cx="29" cy="40" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="29.1" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="50.9" r="4.4" />
                                            <line class="st0" x1="32.3" y1="43.1" x2="43.2" y2="48.8" />
                                            <line class="st0" x1="32.3" y1="36.9" x2="43.2" y2="31.2" />
                                        </g>
                                    </g>
                                </svg>
                                <p>COMPARTIR</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="investigacionM">
                <template v-for="(pr, index) in proyectosM" :key="index">
                    <div class="" v-if="pr.categoria == 'Investigación CN' || pr.categoria == 'Investigación CS'">
                        
                            <img class="imgStands" src="/assets/img/stand-investigacion-MEDIA.svg" alt="">
                        
                        <div class="ico-stands">
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'TEXT', title: 'RESUMEN', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <g>
                                                <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                            </g>
                                            <line class="st0" x1="31.8" y1="31" x2="38.6" y2="31" />
                                            <line class="st0" x1="31.8" y1="34.4" x2="38.6" y2="34.4" />
                                            <line class="st0" x1="31.8" y1="38.3" x2="50.1" y2="38.3" />
                                            <line class="st0" x1="31.8" y1="42.1" x2="50.1" y2="42.1" />
                                            <line class="st0" x1="31.8" y1="46" x2="50.1" y2="46" />
                                            <line class="st0" x1="31.8" y1="49.8" x2="50.1" y2="49.8" />
                                        </g>
                                    </g>
                                </svg>
                                <p>RESUMEN</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'VIDEO', title: 'VIDEO', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <path class="st1" d="M55.8,37.1L33.5,24.2c-2-1.2-4.5,0.3-4.5,2.6v25.8c0,2.3,2.5,3.8,4.5,2.6l22.3-12.9C57.9,41.2,57.9,38.3,55.8,37.1z" />
                                    </g>
                                </svg>
                                <p>VIDEO</p>
                            </div>
                            <div>
                                <a :href="`${pr.links.infografia}`" target="_blank" >
                                    <svg id="Capa_1" 
                                        class="iconos" 
                                        version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        x="0px" 
                                        y="0px" 
                                        viewBox="0 0 80 80"
                                        style="enable-background:new 0 0 80 80;" 
                                        xml:space="preserve"
                                    >

                                        <g>
                                            <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                            <g>
                                                <g>
                                                    <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                    <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                                </g>
                                                <circle class="st0" cx="33.9" cy="37.9" r="2.6" />
                                                <polyline class="st0" points="54.8,50.7 47.2,43.4 33.5,56.7 		" />
                                                <polyline class="st0" points="39.4,50.7 34,45.6 27.9,51.5 		" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <p>INFOGRAFIA</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'SHARE', title: 'COMPARTIR', data: $route })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <circle class="st1" cx="29" cy="40" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="29.1" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="50.9" r="4.4" />
                                            <line class="st0" x1="32.3" y1="43.1" x2="43.2" y2="48.8" />
                                            <line class="st0" x1="32.3" y1="36.9" x2="43.2" y2="31.2" />
                                        </g>
                                    </g>
                                </svg>
                                <p>COMPARTIR</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>        
            <div class="innovacionM">
                <template v-for="(pr, index) in proyectosM" :key="index">
                    <div class=""  v-if="pr.categoria == 'Innovación'">
                        
                            <img class="imgStands" src="/assets/img/stand-innovacion-MEDIA.svg" alt="">
                        
                        <div class="ico-stands">
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'TEXT', title: 'RESUMEN', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <g>
                                                <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                            </g>
                                            <line class="st0" x1="31.8" y1="31" x2="38.6" y2="31" />
                                            <line class="st0" x1="31.8" y1="34.4" x2="38.6" y2="34.4" />
                                            <line class="st0" x1="31.8" y1="38.3" x2="50.1" y2="38.3" />
                                            <line class="st0" x1="31.8" y1="42.1" x2="50.1" y2="42.1" />
                                            <line class="st0" x1="31.8" y1="46" x2="50.1" y2="46" />
                                            <line class="st0" x1="31.8" y1="49.8" x2="50.1" y2="49.8" />
                                        </g>
                                    </g>
                                </svg>
                                <p>RESUMEN</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'VIDEO', title: 'VIDEO', data: pr })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <path class="st1" d="M55.8,37.1L33.5,24.2c-2-1.2-4.5,0.3-4.5,2.6v25.8c0,2.3,2.5,3.8,4.5,2.6l22.3-12.9C57.9,41.2,57.9,38.3,55.8,37.1z" />
                                    </g>
                                </svg>
                                <p>VIDEO</p>
                            </div>
                            <div>
                                <a :href="`${pr.links.infografia}`" target="_blank" >
                                    <svg id="Capa_1" 
                                        class="iconos" 
                                        version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        x="0px" 
                                        y="0px" 
                                        viewBox="0 0 80 80"
                                        style="enable-background:new 0 0 80 80;" 
                                        xml:space="preserve"
                                    >
                                        <g>
                                            <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                            <g>
                                                <g>
                                                    <path class="st1" d="M50.4,56.7H32c-2.5,0-4.5-2-4.5-4.5V27.8c0-2.5,2-4.5,4.5-4.5h11.2l11.7,10.8v18C54.8,54.7,52.8,56.7,50.4,56.7z" />
                                                    <path class="st0" d="M53.9,34h-7.1c-2.5,0-4.5-2-4.5-4.5v-6.2" />
                                                </g>
                                                <circle class="st0" cx="33.9" cy="37.9" r="2.6" />
                                                <polyline class="st0" points="54.8,50.7 47.2,43.4 33.5,56.7 		" />
                                                <polyline class="st0" points="39.4,50.7 34,45.6 27.9,51.5 		" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                                <p>INFOGRAFIA</p>
                            </div>
                            <div>
                                <svg id="Capa_1" 
                                    class="iconos" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                                    x="0px" 
                                    y="0px" 
                                    viewBox="0 0 80 80"
                                    style="enable-background:new 0 0 80 80;" 
                                    xml:space="preserve"
                                    @click="onToggleModal({ type: 'SHARE', title: 'COMPARTIR', data: $route })"
                                >
                                    <g>
                                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9H40.4z" />
                                        <g>
                                            <circle class="st1" cx="29" cy="40" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="29.1" r="4.4" />
                                            <circle class="st1" cx="47.6" cy="50.9" r="4.4" />
                                            <line class="st0" x1="32.3" y1="43.1" x2="43.2" y2="48.8" />
                                            <line class="st0" x1="32.3" y1="36.9" x2="43.2" y2="31.2" />
                                        </g>
                                    </g>
                                </svg>
                                <p>COMPARTIR</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </main>
    
    <footer class="footer">
        <footerLogo />
    </footer>
    
    <BootstrapModal ref="bsModal" 
        :size="modalSetting.size"
        :vcentered="modalSetting.vcentered"
        @hidden-modal="hiddenModal"
    >
        <template v-slot:title>
            <h5 v-if="modalData.title" class="py-0 my-auto">
                {{ modalData.title }}
            </h5>
        </template>
        <template v-slot:body>
            <div v-if="modalData.type === 'TEXT'" class="px-3">
                <span class="fs-6 fw-bolder text-justify d-inline-block mb-3">
                    {{ modalData.content.title }}
                </span>
                <p class="fs-7 text-justify">
                    {{ modalData.content.text }}
                </p>
            </div>
            <div v-else-if="modalData.type === 'VIDEO'" class="youtube-container">
                <iframe v-if="modalData.content.url" 
                    class="youtube-iframe rounded bg-dark"
                    :src="`${modalData.content.url}?controls=0`" 
                ></iframe>
                <div v-else class="youtube-container bg-gray-300 rounded">
                    <div class="youtube-iframe text-center d-flex flex-wrap align-items-center">
                        <img src="@/assets/no-video-1.png" 
                            class="img-fluid mx-auto"
                            style="width: 20%;"
                        >
                    </div>
                </div>
            </div>
            <div v-else-if="modalData.type === 'SHARE'" class="share-modal">
                <SocialNetworksShare :text="modalData.content.url" />
            </div>
        </template>
    </BootstrapModal>
</template>

<script>
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import mnRegiones from '@/components/mnRegiones.vue'
import { mapState, mapMutations } from 'vuex'

import BootstrapModal from '@/components/BootstrapModal.vue'
import SocialNetworksShare from '@/components/SocialNetworksShare.vue'

export default {
    name: 'Feria',
    components: {
        mnSup, footerLogo, mnRegiones, BootstrapModal, SocialNetworksShare
    },
    data() {
        return {
            meta: { title: this.$route.meta.title },
            param: parseInt(this.$route.params.r),
            region: null,
            proyectosB: null,
            proyectosM: null,
            RMselect: '',
            reg: '',
            modalSetting: { size: 'nm', vcentered: true },
            modalData: { type: undefined, title: undefined, content: undefined },
            bsPopoverList: undefined
        }
    },
    async created() {
        await this.initStand()
    },
    methods: {
        ...mapMutations(['showModal']),
        async initStand() {
            document.title = 'STANDS - XXI Congreso Nacional Explora'
            await this.loadRegion()

            const selectedRegionId = parseInt(this.$route.params.r)

            let selectedRMZone = undefined
            if (selectedRegionId === 7) {
                selectedRMZone = this.$route.hash
                if (selectedRMZone !== '') {
                    selectedRMZone = parseInt(selectedRMZone.split('rm')[1])
                }
            }

            await this.loadProyectos(selectedRMZone)
        },
        async loadRegion() {
            this.region = null;
            let url = `/bdd/regiones.json`;
            try {
                let rs = await fetch(url);
                let data = await rs.json();
                data = data.salida;
               
                let r = data.filter((data) => {
                  
                    return data.order == this.param;
                    
                });

                this.region = r[0];
            } catch (e) {
                console.log(e);
            }
        },
        async loadProyectos(x) {
            let id;
            let r = this.region;
            if (x != undefined && x != '' && x != null) {
                id = x;
            } else {
                id = r.id;
            }

            this.RMselect = id

            let url = `${this.apiURL}/api/?clase=MasterData&metodo=getProyectFiltered&parametros={"filters":[{"field":"PAR", "value":${id}}]}`;
            //console.warn(url);

            try {
                let rs = await fetch(url);
                let datosRaw = await rs.arrayBuffer();
                let decoder = new TextDecoder("ISO-8859-1");
                let datosDec = decoder.decode(datosRaw);
                let data = JSON.parse(datosDec);
                data = data.salida;

                this.proyectosB = data.filter((data) => {
                    return data.nivel == "Básica";
                });
                this.proyectosM = data.filter((data) => {
                    return data.nivel == "Media";
                });

            } catch (e) {
                console.log(e);
            }
        },
        cambiarRegion(n) {
            console.log(n)
            if (n > 0 && n < 17) {
                window.location.href = `/feria/${n}`;
            }
        },
        modalResumen(pr){
            console.log('hola: ', pr)
            
            this.showModal();
            
            console.log('Abriendo modal Resumen');
            
        },
        widthScreen(){
            if(window.innerWidth>1100){
                return true
            }else{
                return false
            }
        },
        onToggleModal({ type, title, data }) {
            // console.log(data)
            if (data && typeof(data) === 'object') {
                switch (type) {
                    case 'TEXT':
                        this.modalSetting.size = 'xl'

                        this.modalData.type = type
                        this.modalData.title = title
                        this.modalData.content = { title: data.titulo, text: data.resumen }
                        
                        break
                    case 'VIDEO':
                        this.modalSetting.size = 'xl'

                        this.modalData.type = type
                        this.modalData.title = title
                        this.modalData.content = { 
                            url: data.links.video.stand && data.links.video.stand !== '' 
                                    ? data.links.video.stand 
                                    : undefined 
                        }
                        
                        break
                    case 'SHARE':
                        let hashId = parseInt(this.RMselect)

                        this.modalSetting.size = 'nm'

                        this.modalData.type = type
                        this.modalData.title = title
                        this.modalData.content = { 
                            url: `${window.location.origin}${data.path}${[13, 17, 18].includes(hashId) ? `#rm${hashId}`: ''}` 
                        }
                        
                        break
                    default:
                        this.modalData.type = undefined
                        this.modalData.title = undefined
                        this.modalData.content = undefined
                        break
                }
                
                if (this.modalData.type) {
                    this.$refs.bsModal.toggle()
                }

                return
            }
            
            this.modalSetting.size = 'xl'
            this.modalSetting.vcentered = true
            
            this.modalData.type = undefined
            this.modalData.title = undefined
            this.modalData.content = undefined
        },
        hiddenModal() {
            this.modalSetting.size = 'nm'
            this.modalSetting.vcentered = true

            this.modalData.type = undefined
            this.modalData.title = undefined
            this.modalData.content = undefined
        }
    },
    computed: {
        ...mapState(['apiURL']),
    }
}
</script>

<style lang="scss" scoped>
main#stand {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    width: 100%;
    min-height: 100%;

    @media(max-width:1100px) {
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        gap: 1em;
    }
    .header {
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;
        justify-items: center;
        width: 100%;
        min-height: 100%;
        aside{
            margin-top: 0;
        }
        @media(max-width: 1100px) {
            display: grid;
            grid-template-columns: 100%;
            justify-items: center;
            
        }
    }
    .imgHeader {
        position: relative;
        display: inline-block;
        text-align: center;
        margin-top: 100px;
        width: 80%;
        margin-left: 200px;
        z-index: -5;
    }
    .imgMobile{
        position: relative;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .nombre {
        position: relative;
        padding: 20px;
        margin-bottom: 115px;
        display: flex;
        align-items: center;
        text-align: center;

        @media(max-width:1100px) {
            flex-direction: column-reverse;
        }
        &.RMstand {
            margin-bottom: 0px;
        }
    }
    ul.RM-group {
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: -10px;

        li {
            list-style: none !important;
            padding: 0px 30px 0px 30px;
            display: inline;

            p {
                font-size: 1.5em;
                font-weight: 500;
                line-height: 0.9em !important;
                text-align: center;
                color: var(--color3) !important;
                cursor: pointer !important;
                padding: 5px !important;

                &:hover {
                    color: var(--color2) !important;
                }

                &.active {
                    color: var(--color2) !important;
                    border-bottom: 3px solid var(--color2) !important;
                }
            }
        }
        @media(max-width:1023px) {
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 10px;
            padding-left: 0;
                
            li{
            
                p{
                font-size: 1.3em;
            }
        
            }

        }
    }
    .grilla {
        display: grid;
        grid-template-columns: 10% 40% 40% 10%;
        grid-template-rows: 50px  400px 400px;
        grid-template-areas: ". t1 t2 ."
                             ". s1 s2 ."
                             ". s3 s4 .";
        align-items: center;
        justify-items: stretch;
        width: 100%;
        position: relative;
        
        @media(max-width:1100px) {
            display: grid;
            grid-template-columns: 10% 80% 10%;
            grid-template-rows: 50px 250px 250px 50px 250px 250px;
            grid-template-areas:". a ."
                                ". b ."
                                ". c ."
                                ". d ."
                                ". e. "
                                ". f ." !important ;
            position: relative; 
            justify-items: stretch;       
            padding: 0;
        }
        .cat1 {
            grid-area: t1;
            width: fit-content;
            position: relative;
            justify-self: center;
            text-align: center;
            @media(max-width:1100px){
                grid-area: a;
                width: auto;
                text-align: center;
                font-size: 0.7rem;                
                margin: 0;
            }
        }
        .cat2 {
            grid-area:t2;
            width: fit-content;
            position: relative;
            justify-self: center;
            text-align: center;
            @media(max-width:1100px){
                grid-area: d;
                width: auto;
                text-align: center;
                font-size: 0.7rem;              
                margin: 0;
            }
        }


        .investigacionB{
                grid-area:s1;
                align-items: center;
                .imgStands{
                    width: 400px;
                    z-index:1;
                    margin: 60px;
                    margin-left: 180px;
                    position: absolute;
                    top:100px;
                }  
                .ico-stands{
                    width: auto;
                    display: flex;
                    text-align: center;
                    font-size: 0.7rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:250px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 200px;              
                } 
            @media(max-width:1400px){
                grid-area:s1;
                align-items: center;
                .imgStands{
                    width: 400px;
                    z-index:1;
                    margin: 60px;
                    margin-left: 80px;
                    position: absolute;
                    top:100px;
                }  
                .ico-stands{
                    width: auto;
                    display: flex;
                    text-align: center;
                    font-size: 0.7rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:250px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 100px;              
                } 
            }     
            @media(max-width:1100px){
                grid-area: b;    
                text-align: center !important;
                margin-left: -240px;        
                .imgStands{
                    grid-area: b;
                    width: 250px;
                    z-index:1;
                    margin: 0;
                    position: absolute;
                    top:50px
                }  
                .ico-stands{
                    grid-area: b;
                    width: auto;
                    display: inline-flex !important;
                    display: flex;
                    text-align: center;
                    font-size: 0.5rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:110px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 20px;              
                }
            }   
        }
        .investigacionM{
            grid-area:s3;
            .imgStands{
                width: 400px;
                z-index:1;
                margin: 60px;
                margin-left: 180px;
                position: absolute;
                top:400px;
            } 
            .ico-stands{
                width: auto;
                display: flex;
                text-align: center;
                font-size: 0.7rem;
                color: var(--color3);
                align-content: space-between;
                position: absolute;
                top:550px;
                z-index: 100;
                padding: 0;
                margin-left: 200px;
                
            }
            @media(max-width:1400px){
                grid-area:s1;
                align-items: center;
                .imgStands{
                    width: 400px;
                    z-index:1;
                    margin: 60px;
                    margin-left: 80px;
                    position: absolute;
                    top:400px;
                }  
                .ico-stands{
                    width: auto;
                    display: flex;
                    text-align: center;
                    font-size: 0.7rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:550px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 100px;              
                } 
            } 
            @media(max-width:1100px){
                grid-area: c;
                justify-items: center;
                text-align: center !important;
                margin-left: -240px;
                .imgStands{
                    grid-area: c;
                    width: 250px;
                    z-index:1;
                    margin: 0;
                    position: absolute;
                    top: 20px;
                } 
                .ico-stands{
                    grid-area: c;
                    width: auto;
                    display: inline-flex !important;
                    display: flex;
                    text-align: center;
                    font-size: 0.5rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:80px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 20px;
                }
            }     
        }
        .innovacionB{
            grid-area:s2;
            .imgStands{
                width: 400px;
                z-index:1;
                margin: 60px;
                margin-left: 180px;
                position: absolute;
                top:100px;
            } 
            .ico-stands{
                width: auto;
                display: flex;
                text-align: center;
                font-size: 0.7rem;
                color: var(--color3);
                align-content: space-between;
                position: absolute;
                top:250px;
                z-index: 100;
                padding: 0;
                margin-left: 200px;
            }
            @media(max-width:1400px){
                grid-area:s2;
                .imgStands{
                    width: 400px;
                    z-index:1;
                    margin: 60px;
                    margin-left: 80px;
                    position: absolute;
                    top:100px;
                } 
                .ico-stands{
                    width: auto;
                    display: flex;
                    text-align: center;
                    font-size: 0.7rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:250px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 100px;
                } 
            }    
            @media(max-width:1100px){
                grid-area: e;
                justify-items: center;
                text-align: center !important;
                margin-left: -240px;
                .imgStands{
                grid-area: e;
                width: 250px;
                z-index:1;
                margin: 0;
                position: absolute;
                top:40px;
                } 
                .ico-stands{
                    grid-area: e;
                    width: auto;
                    display: inline-flex !important;
                    display: flex;
                    text-align: center;
                    font-size: 0.5rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:100px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 20px;
                }  
            }   
        }
        .innovacionM{
            grid-area:s4;
            .imgStands{
                width: 400px;
                z-index:1;
                margin: 60px;
                margin-left: 180px;
                position: absolute;
                top:400px;
            } 
            .ico-stands{
                width: auto;
                display: flex;
                text-align: center;
                font-size: 0.7rem;
                color: var(--color3);
                align-content: space-between;
                position: absolute;
                top:550px;
                z-index: 100;
                padding: 0;
                margin-left: 200px;
            } 
            @media(max-width:1400px){
                grid-area:s2;
                .imgStands{
                    width: 400px;
                    z-index:1;
                    margin: 60px;
                    margin-left: 80px;
                    position: absolute;
                    top:400px;
                } 
                .ico-stands{
                    width: auto;
                    display: flex;
                    text-align: center;
                    font-size: 0.7rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:550px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 100px;
                } 
            }    
            @media(max-width:1100px){
                grid-area: f;
                justify-items: center;
                text-align: center !important;
                margin-left: -240px;
                .imgStands{
                    grid-area: f;
                    width: 250px;
                    z-index:1;
                    margin: 0;
                    position: absolute;
                    top: 20px;
                }
                .ico-stands{
                    grid-area: f;
                    width: auto;
                    display: inline-flex !important;
                    display: flex;
                    text-align: center;
                    font-size: 0.5rem;
                    color: var(--color3);
                    align-content: space-between;
                    position: absolute;
                    top:80px;
                    z-index: 100;
                    padding: 0;
                    margin-left: 20px;
                    
                }  
            } 
        }
        .iconos {
            width: 90px;
            height: 90px;
            z-index: 100;
            @media(max-width:1100px){
                width: 50px;
                height: 50px;
                z-index: 100;
            }
        }
    }

    @media(max-width:1100px) {
        aside {
            display: none;
        }
    }
    .footer{
        position:relative;
        bottom: 0;
    }
}
.text-justify {
    text-align: justify !important;
}
.text-indent {
    text-indent: 4em !important;
}
.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50.25%;
}
.youtube-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.loading-video {
    background-image: url('../assets/loading-video-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
.bg-gray-300 {
    background-color: #f1f1f1;
}
.share-modal {
    height: 180px;
}


</style>