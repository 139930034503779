<template>
    <div class="mt-2 mb-4 d-flex justify-content-center">
        <div class="col-4 text-center">
            <a class="social-link" 
                @click.prevent="shareOnSocialNetworks('TWITTER', shareText)"
            ><img src="../assets/icons/twitter-icon-1.png" class="img-fluid"></a>
        </div>
        <div class="col-4 text-center">
            <a class="social-link" 
                @click.prevent="shareOnSocialNetworks('WHATSAPP', shareText)"
            ><img src="../assets/icons/whatsapp-icon-1.png" class="img-fluid"></a>
        </div>
    </div>
    <hr class="dropdown-divider">
    <div class="input-group mt-4">
        <input v-model="shareText" 
            class="form-control" 
            type="text" 
            readonly 
            disabled
        >
        <button class="btn btn-outline-secondary" 
            type="button"
            @click="copyToClipboard(shareText)"
        ><font-awesome-icon :icon="['far', 'copy']" /></button>
    </div>

    <div class="toast-container position-absolute p-3">
        <div id="copyText" 
            class="toast text-white border-0" :class="copyAlertColor"
            role="alert" 
            aria-live="polite" 
            aria-atomic="true"
        >
            <div class="d-flex">
                <div class="toast-body">
                    {{ copyAlertText }}
                </div>
                <button type="button" 
                    class="btn-close btn-close-white me-2 m-auto" 
                    data-bs-dismiss="toast" 
                    aria-label="Close"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import { Toast } from '@/assets/bootstrap/bootstrap.bundle.min.js'

export default {
    name: 'SocialNetworksShare',
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const copyAlert = ref()
        const copyAlertText = ref('')
        const copyAlertColor = ref('bg-primary')

        const shareOnSocialNetworks = (social, data) => {
            let url = undefined
            
            switch (social) {
                case 'FACEBOOK':
                    url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(data)}`
                    break;
                case 'TWITTER':
                    url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(data)}`
                    break;
                case 'WHATSAPP':
                    url = `https://api.whatsapp.com/send?text=${encodeURIComponent(data)}`
                    break;
                case 'LINKEDIN':
                    url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(data)}`
                    break;
                default:
                    url = undefined
                    break;
            }

            if (url) {
                window.open(url, '_blank');
            }
        }

        const copyToClipboard = async (data) => {
            if (typeof(data) === 'string') {
                try {
                    await navigator.clipboard.writeText(data)
                    copyAlertText.value = 'URL copiada al portapapeles'
                    copyAlertColor.value = 'bg-primary'
                    if (copyAlert.value) {
                        copyAlert.value.show()
                    }
                    console.log('Content copied to clipboard')
                } catch (error) {
                    copyAlertText.value = `No se pudo copiar la URL al portapapeles (${error.message}) `
                    copyAlertColor.value = 'bg-danger'
                    if (copyAlert.value) {
                        copyAlert.value.show()
                    }
                    console.error('Failed to copy: ', error)
                }
            }
        }

        onMounted(() => {
            copyAlert.value = new Toast(document.getElementById('copyText'), {
                animation: true,
                autohide: true,
                delay: 2000
            })

            document.getElementById('copyText').addEventListener('hidden.bs.toast', () => {
                copyAlertText.value = ''
                copyAlertColor.value = 'bg-primary'
            })
        })

        return {
            shareText: computed(() => props.text),
            copyAlertText,
            copyAlertColor,

            shareOnSocialNetworks,
            copyToClipboard,
        }
    }
}
</script>

<style lang="scss" scoped>
a.social-link {
    img {
        width: 85px;
        
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }
}
.toast-container {
    margin: 15px 0 0 -32px; 
    opacity: 0.8;
}
</style>